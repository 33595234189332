import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Link from "components/Link"
import { throttle } from "lodash-es"
import StockTicker from "./Header/StockTicker"
import NavButton from "components/navigation/NavButton"
import DesktopNav from "components/navigation/DesktopNav"
import MobileNav from "components/navigation/MobileNav"
import InvisibleButton from "components/buttons/InvisibleButton"
import useHeaderNavigation from "hooks/useHeaderNavigation"
import useMediaQuery from "hooks/useMediaQuery"
import theme from "styles/theme"

// Images
import { ReactComponent as MarathonLogo } from "images/header/headerLogo.svg"
import { ReactComponent as NavIcon } from "images/header/navIcon.svg"
import { ReactComponent as SearchIcon } from "images/icons/search.svg"

const GlobalHeader = ({ setSearchOpen }) => {
  const sections = useHeaderNavigation()
  const mobileNav = useMediaQuery(theme.navBreak)

  return (
    <>
      <header
        css={{
          position: "relative",
          zIndex: 30,
          [theme.navBreak]: {
            position: "sticky",
            top: 0,
            background: "#fff",
          },
        }}
      >
        <MobileHeader id="mobile-header" sections={sections} setSearchOpen={setSearchOpen} />
        <DesktopHeader id="desktop-header" sections={sections} setSearchOpen={setSearchOpen} />
      </header>

      {!mobileNav && <StickyHeader id="sticky-header" sections={sections} />}
    </>
  )
}

export default GlobalHeader

GlobalHeader.propTypes = {
  setSearchOpen: PropTypes.func,
}

const DesktopHeader = ({
  id = null,
  as: Component = 'div',
  sections,
  setSearchOpen,
  condensed = false,
  className,
  ...rest
}) => (
  <Component
    id={id}
    css={{
      position: "relative", // ensure shadow shows over siblings
      backgroundColor: theme.white,
      boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
      transform: "translateZ(1px)", // prevent Safari glitching on nav item hover
      [theme.navBreak]: {
        display: 'none'
      },
    }}
    className={className}
    {...rest}
  >
    <div
      css={{
        maxWidth: 1220,
        margin: "0 auto",
        padding: "0 20px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Link href="/" aria-label="Marathon Oil homepage">
        <MarathonLogo
            css={{
                width: 170,
                marginTop: condensed ? 25 : 45,
                marginBottom: 24,
            }}
        />
      </Link>

      {condensed ? (
        <DesktopNav sections={sections} css={{ marginRight: -10 }} />
      ) : (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div
            css={{
              display: "flex",
            }}
          >
            {setSearchOpen && (
              <InvisibleButton
                css={{
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  padding: "1px 0",
                  marginRight: 22,
                  fontWeight: 500,
                  fontSize: 14,
                  color: theme.midBlue,
                  cursor: "pointer",
                  transition: "color 250ms, border-bottom-color 250ms",
                  borderBottom: `2px solid transparent`,
                  ":hover": {
                    color: theme.deepBlue,
                    borderBottomColor: "currentColor",
                  },
                }}
                onClick={() => {
                  setSearchOpen(true)
                }}
              >
                <span css={{ display: "inline-block", marginRight: 12 }}>
                  Search
                </span>
                <SearchIcon css={{ width: 13 }} aria-hidden />
              </InvisibleButton>
            )}

            <StockTicker />
          </div>
          <DesktopNav sections={sections} css={{ marginRight: -10 }} />
        </div>
      )}
    </div>
  </Component>
)

DesktopHeader.propTypes = {
  id: PropTypes.string,
  as: PropTypes.string,
  sections: PropTypes.array.isRequired,
  className: PropTypes.string,
  setSearchOpen: PropTypes.func,
  condensed: PropTypes.bool,
}

const StickyHeader = ({ id = null, sections, className }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setVisible(window.pageYOffset > Math.max(300, window.outerHeight / 2))
    })

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <DesktopHeader
      id={id}
      as="header"
      sections={sections}
      condensed
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 30,
        "--justifiedNavDropdownOffset": "45px",
        transition: "transform 250ms",
        transform: `translateY(${
          visible ? 0 : -100
        }px) translateZ(1px) rotateX(${visible ? 0 : 90}deg)`,
        visibility: visible ? "visible" : "hidden",
      }}
      aria-hidden={!visible}
      className={className}
    />
  )
}

StickyHeader.propTypes = {
  id: PropTypes.string,
  sections: PropTypes.array.isRequired,
  className: PropTypes.string,
}

const MobileHeader = ({ id = null, sections, setSearchOpen, className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div id={id} className={className}>
      <div
        css={{
          display: "none",
          justifyContent: "space-between",
          borderBottom: `1px solid ${theme.slate}`,
          [theme.navBreak]: {
            display: 'flex'
          },
        }}
      >
        <NavButton
          text="Menu"
          icon={
            <NavIcon
              css={{
                width: 18,
                [theme.mobile]: {
                  width: 16,
                },
              }}
            />
          }
          onClick={() => setIsMenuOpen(true)}
        />

        <Link
          href="/"
          css={{
            alignSelf: "center",
            visibility: isMenuOpen ? "hidden" : "visible",
          }}
          aria-hidden={isMenuOpen}
          aria-label="Marathon Oil homepage"
        >
          <MarathonLogo
            css={{
              width: 136,
              [theme.mobile]: { width: 123 },
            }}
          />
        </Link>

        {setSearchOpen ? (
          <NavButton
            text="Search"
            icon={<SearchIcon css={{ width: 13 }} aria-hidden />}
            css={{
              color: theme.midBlue,
              visibility: isMenuOpen ? "hidden" : "visible",
            }}
            aria-hidden={isMenuOpen}
            onClick={() => {
              setSearchOpen(true)
            }}
          />
        ) : (
          <div css={{ width: 134, [theme.mobile]: { width: 75 } }} />
        )}
      </div>

      {isMenuOpen && (
        <MobileNav sections={sections} closeMenu={() => setIsMenuOpen(false)} />
      )}
    </div>
  )
}

MobileHeader.propTypes = {
  id: PropTypes.string,
  sections: PropTypes.array.isRequired,
  className: PropTypes.string,
  setSearchOpen: PropTypes.func,
}
